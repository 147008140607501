import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import ErrorMessage from '../Message';
import { useAuth } from '../../context/AuthContext';

async function fetchEvents() {
  const response = await axios.get('/events');
  return response.data;
}

function formatDate(dateStr) {
  if (!dateStr) return 'N/A';
  const dateObj = new Date(dateStr);
  if (isNaN(dateObj.getTime())) return dateStr;
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return dateObj.toLocaleDateString(undefined, options);
}

const EventsList = () => {
  const { state } = useAuth();
  const isAuthenticated = state?.isAuthenticated;

  const [filterState, setFilterState] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterEventType, setFilterEventType] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [viewMode, setViewMode] = useState('grid');
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: events,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['events'],
    queryFn: fetchEvents,
    staleTime: 1000 * 60 * 5,
  });

  if (isLoading) {
    return (
      <div style={{ backgroundColor: '#f8f8f8' }} className="min-h-screen p-6">
        <p>Loading events...</p>
      </div>
    );
  }

  if (error) {
    const errorMessage = error.response
      ? `Error (${error.response.status}): ${
          error.response.data?.message || JSON.stringify(error.response.data)
        }`
      : `Error: ${error.message}`;
    return (
      <div style={{ backgroundColor: '#f8f8f8' }} className="min-h-screen p-6">
        <ErrorMessage message={errorMessage} />
      </div>
    );
  }

  // Build unique filters
  const allStates = Array.from(
    new Set(events?.map((evt) => evt.state).filter(Boolean))
  );
  const allCities = Array.from(
    new Set(
      events
        ?.filter((evt) => (filterState ? evt.state === filterState : true))
        .map((evt) => evt.city)
        .filter(Boolean)
    )
  );
  const allEventTypes = Array.from(
    new Set(events?.map((evt) => evt.eventType).filter(Boolean))
  );

  // Filter logic
  const filteredEvents = (events || []).filter((evt) => {
    if (filterState && evt.state !== filterState) return false;
    if (filterCity && evt.city !== filterCity) return false;
    if (filterEventType && evt.eventType !== filterEventType) return false;
    return true;
  });

  function getPricePlaceholder() {
    return '₹999 onwards';
  }

  function StarRating() {
    return (
      <div className="flex items-center space-x-1">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className="w-4 h-4 text-yellow-500 fill-current"
            viewBox="0 0 24 24"
          >
            <path d="M12 .587l3.668 7.431 8.213 1.179-5.941 5.79 1.408 8.188L12 18.896l-7.348 3.878 1.408-8.188-5.94-5.79 8.212-1.179z" />
          </svg>
        ))}
      </div>
    );
  }

  function ActivityIcon({ eventType }) {
    if (eventType === 'Running') {
      return (
        <svg
          className="w-5 h-5 text-blue-600 mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M13.49 2.07a2.49 2.49 0 100 4.98 2.49 2.49 0 000-4.98zM14 8H8.5c-.28 0-.53.16-.66.41l-1.75 3.5A.75.75 0 006 13h1.25l-1.72 4.3a.75.75 0 001.4.56l2-5a.75.75 0 00-.07-.67L7.98 11H14a.75.75 0 00.53-1.28l-2-2A.75.75 0 0012 7H9.21l.56-1.11A.75.75 0 0010.75 5h2.47A1.75 1.75 0 0115 6.75v.5A.75.75 0 0114 8z" />
        </svg>
      );
    } else if (eventType === 'Cycling') {
      return (
        <svg
          className="w-5 h-5 text-blue-600 mr-1"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M5 14a3 3 0 100 6 3 3 0 000-6zm0 8A5 5 0 015 12a5 5 0 010 10zM19 14a3 3 0 100 6 3 3 0 000-6zm0 8a5 5 0 110-10 5 5 0 010 10zM13 2h-2v3H8a1 1 0 000 2h3l3.34 5.558A5 5 0 0116 12.9V18a1 1 0 102 0v-5.1a5 5 0 01-2.663-6.588L13 4V2z" />
        </svg>
      );
    } else if (eventType === 'Triathlon') {
      return (
        <svg
          className="w-5 h-5 text-blue-600 mr-1"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M2 15h3a1 1 0 010 2H2a1 1 0 010-2zm17.8 3.4l1.4 1.4A1 1 0 0120 22h-2a1 1 0 01-.8-1.6l1.4-1.4a1 1 0 011.4 0zM18 2a2 2 0 110 4 2 2 0 010-4zM8.39 6.45c.37-.34.9-.45 1.39-.45h2c.44 0 .86.19 1.16.52L16.08 10H20a1 1 0 010 2h-4a1 1 0 01-.8-.4l-2-2.5-3.89 4.1 2.38 2.38A3 3 0 0113 18.83v2.25a1 1 0 11-2 0v-2.25a1 1 0 00-.29-.7l-3.61-3.61A3 3 0 015.59 12l2.8-3.54z" />
        </svg>
      );
    }
    return (
      <svg
        className="w-5 h-5 text-blue-600 mr-1"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2a5 5 0 01.17 10h-.34A5 5 0 0112 2zM2 19a6 6 0 0112 0v3H2v-3zm14 0a5.98 5.98 0 011.74-4.24c.17.48.26.99.26 1.52v5h-2v-2.28z" />
      </svg>
    );
  }

  function LocationPinIcon() {
    return (
      <svg
        className="w-5 h-5 text-orange-600 inline-block mr-1"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2C8.685 2 6 4.685 6 8c0 4.28 6 12 6 12s6-7.72 6-12c0-3.315-2.685-6-6-6zm0 8.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" />
      </svg>
    );
  }

  return (
    <>
      <nav className="w-full bg-white shadow-sm px-6 py-3 flex justify-between items-center">
        <div className="flex gap-6 font-normal text-black">
          <Link to="/" className="hover:underline">
            Home
          </Link>
          <Link to="/events" className="hover:underline">
            Events
          </Link>
          <Link to="/about" className="hover:underline">
            About
          </Link>
          <Link to="/profile" className="hover:underline">
            Profile
          </Link>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="py-2 px-3 bg-gray-100 text-black rounded hover:bg-gray-200 transition"
          >
            Filter
          </button>
          <div className="flex">
            <button
              onClick={() => setViewMode('list')}
              className={`py-2 px-3 border border-r-0 border-gray-300 ${
                viewMode === 'list' ? 'bg-gray-300' : 'bg-gray-100'
              }`}
            >
              List
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`py-2 px-3 border border-gray-300 ${
                viewMode === 'grid' ? 'bg-gray-300' : 'bg-gray-100'
              }`}
            >
              Grid
            </button>
          </div>
        </div>
      </nav>

      <div style={{ backgroundColor: '#f8f8f8' }} className="min-h-screen p-6">
        {showFilters && (
          <div className="mb-6 bg-white rounded shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-2">Filter Events</h2>
            <div className="flex flex-wrap gap-4 md:flex-row md:gap-6">
              <div className="flex flex-col">
                <label
                  htmlFor="stateFilter"
                  className="mb-1 font-medium text-sm text-gray-700"
                >
                  State
                </label>
                <select
                  id="stateFilter"
                  className="border rounded px-2 py-1"
                  value={filterState}
                  onChange={(e) => {
                    setFilterState(e.target.value);
                    setFilterCity('');
                  }}
                >
                  <option value="">-- All --</option>
                  {allStates.map((st) => (
                    <option key={st} value={st}>
                      {st}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="cityFilter"
                  className="mb-1 font-medium text-sm text-gray-700"
                >
                  City
                </label>
                <select
                  id="cityFilter"
                  className="border rounded px-2 py-1"
                  value={filterCity}
                  onChange={(e) => setFilterCity(e.target.value)}
                >
                  <option value="">-- All --</option>
                  {allCities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="typeFilter"
                  className="mb-1 font-medium text-sm text-gray-700"
                >
                  Event Type
                </label>
                <select
                  id="typeFilter"
                  className="border rounded px-2 py-1"
                  value={filterEventType}
                  onChange={(e) => setFilterEventType(e.target.value)}
                >
                  <option value="">-- All --</option>
                  {allEventTypes.map((et) => (
                    <option key={et} value={et}>
                      {et}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {!filteredEvents || filteredEvents.length === 0 ? (
          <p className="text-gray-600 mt-4">No events found.</p>
        ) : (
          <div
            className={
              viewMode === 'grid'
                ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
                : 'flex flex-col space-y-4'
            }
          >
            {filteredEvents.map((event) => {
              const eventLink = isAuthenticated
                ? `/events/${event.EventID}`
                : `/events/public/${event.EventID}`;
              const dateBadge = formatDate(event.date);
              const eventType = event.eventType || 'Other';
              const coverImageUrl = event.coverImage
                || 'https://via.placeholder.com/640x360.png?text=Event+Banner';

              return (
                <div
                  key={event.EventID}
                  className="bg-white rounded-md shadow hover:shadow-md transition-shadow overflow-hidden"
                >
                  <div className="relative w-full pt-[56.25%] bg-gray-200">
                    <img
                      src={coverImageUrl}
                      alt="Event Banner"
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                    <div className="absolute top-2 left-2 bg-[#90EE90] text-black text-xs font-semibold px-2 py-1 rounded">
                      {dateBadge}
                    </div>
                    <div className="absolute top-2 right-2 w-10 h-10 bg-white rounded-full flex items-center justify-center">
                      <img
                        src="https://via.placeholder.com/36x36?text=Logo"
                        alt="Logo"
                        className="w-8 h-8 rounded-full"
                      />
                    </div>
                  </div>
                  <div className="p-4">
                    <h2 className="text-lg font-bold text-black mb-2 line-clamp-2">
                      {event.title}
                    </h2>
                    <div className="flex items-center justify-between mb-2 text-sm">
                      <div className="flex items-center">
                        <ActivityIcon eventType={eventType} />
                        <span className="mr-2">{eventType}</span>
                        <span className="bg-[#E0F0F0] text-gray-700 px-2 py-0.5 rounded-full text-xs ml-2">
                          {event.city || 'Unknown City'}
                        </span>
                      </div>
                      <StarRating />
                    </div>
                    <div className="flex items-center justify-between text-sm mb-3">
                      <div>
                        <span className="font-medium">{getPricePlaceholder()}</span>
                      </div>
                      <div className="flex items-center text-sm text-gray-600">
                        <LocationPinIcon />
                        <span>{event.state || 'Unknown State'}</span>
                      </div>
                    </div>
                    <Link
                      to={eventLink}
                      className="inline-block bg-pink-500 hover:bg-pink-600 text-white text-sm font-medium py-2 px-4 rounded"
                    >
                      Register
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {filteredEvents && filteredEvents.length > 0 && (
          <div className="mt-8 flex justify-center items-center gap-2 text-gray-600">
            <span
              className={`cursor-pointer ${
                currentPage === 1 ? 'text-black font-semibold' : ''
              }`}
              onClick={() => setCurrentPage(1)}
            >
              1
            </span>
            <span
              className={`cursor-pointer ${
                currentPage === 2 ? 'text-black font-semibold' : ''
              }`}
              onClick={() => setCurrentPage(2)}
            >
              2
            </span>
            <span
              className={`cursor-pointer ${
                currentPage === 3 ? 'text-black font-semibold' : ''
              }`}
              onClick={() => setCurrentPage(3)}
            >
              3
            </span>
            <span>...</span>
          </div>
        )}
      </div>
    </>
  );
};

export default EventsList;